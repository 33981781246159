import * as React from 'react';
import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { anyLetterRegExp, getCountryCodeOptions, getIsTipaltyFlow } from '../../utils/helpers';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import useEmailValidator from '../../hooks/emailValidatior';
import NumberFormat from 'react-number-format';
import StateSelect from '../../components/StateSelect';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { inputCreator, selectCreator } from '../../components/BaseForm';
import { useStyles } from './styles';
import InputPhone from '../../components/InputPhone';
import { divideCodeAndPhoneNumber } from '../../utils/countries';
import {
  saveEditData,
  setHomeStep,
  setHomeUserData,
  setWaitingForWebhook,
} from '../../store/system/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectHomeEdit, selectHomeUserData } from '../../store/system/selectors';
import CustomButtons from '../../components/CustomButtons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import IframeCmp from '@root/pages/IframeStep';
import TipaltiIframe from '@root/components/TipaltiIframe';
import LeavePageModal from '@root/components/LeavePageModal';

interface NumberFormatCustomProps {
  inputRef: (instance: any) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format: string;
}

const getCityAddressSchema = (required: boolean = false, name: string = 'City') => {
  if (name === 'City') {
    if (required) {
      return Yup.string()
        .required(`${name} is required`)
        .max(100, '100 characters max')
        .matches(
          /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢-]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s-]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
          'These characters are not supported'
        );
    }
    return Yup.string()
      .max(100, '100 characters max')
      .matches(
        /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢-]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s-]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
        'These characters are not supported'
      );
  }

  if (required) {
    return Yup.string()
      .required(`${name} is required`)
      .max(100, '100 characters max')
      .matches(
        /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢.,:;+-]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s.,:;+-]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
        'These characters are not supported'
      );
  }
  return Yup.string()
    .max(100, '100 characters max')
    .matches(
      /^[a-zA-Z0-9\u00C0-\u00FFŸŒ¢.,:;+-]+([a-zA-Z0-9\u00C0-\u00FFŸŒ¢\s.,:;+-]?)+[a-zA-Z0-9\u00C0-\u00FFŸŒ¢]+$/,
      'These characters are not supported'
    );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .min(2, 'First Name should contain at least two letters')
    .max(50, '50 characters max')
    .matches(anyLetterRegExp, 'These characters are not supported'),

  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last Name should contain at least two letters')
    .max(50, '50 characters max')
    .matches(anyLetterRegExp, 'These characters are not supported'),

  email: Yup.string().email('Email address is not valid').required('Email address is required'),

  taxId: Yup.string().when('countryCode', (val: string) => {
    const condition = val === 'US' || val === 'CA';
    if (condition) {
      return Yup.string().required('this field is required').min(9, 'must be 9 characters long');
    }
    return Yup.string()
      .max(18, '18 characters max')
      .matches(/^[a-zA-Z0-9]+$/, 'These characters are not supported');
  }),

  postalCode: Yup.string().when('method', (val: string) => {
    const condition = val === 'bankWire';
    if (condition) {
      return Yup.string().required('Postal Code field is required');
    }
    return Yup.string();
  }),

  confirmTaxId: Yup.string().when('taxId', (val: string) => {
    if (val && val.length) {
      return Yup.string()
        .required('this field is required')
        .oneOf([Yup.ref('taxId'), ''], 'Confirm TaxID and TaxID do not match');
    }
    return Yup.string();
  }),

  // FOR CASH AND BANKWIRE ONLY

  address1: Yup.string().when('method', (val: string) => {
    const condition = ['cash', 'bankWire'].includes(val);
    if (condition) {
      return getCityAddressSchema(true, 'Address Line 1');
    }
    return Yup.string();
  }),

  address2: Yup.string().when('method', (val: string) => {
    const condition = val === 'cash';
    if (condition) {
      return getCityAddressSchema(false, 'Address Line 2');
    }
    return Yup.string();
  }),
  mailingAddress1: Yup.string().when('method', (val: string) => {
    const condition = val === 'cash';
    if (condition) {
      return getCityAddressSchema(true, 'Address Line 1');
    }
    return Yup.string();
  }),
  mailingAddress2: Yup.string().when('method', (val: string) => {
    const condition = val === 'cash';
    if (condition) {
      return getCityAddressSchema(false, 'Address Line 2');
    }
    return Yup.string();
  }),
  city: Yup.string().when('method', (val: string) => {
    const condition = ['cash', 'bankWire'].includes(val);
    if (condition) {
      return getCityAddressSchema(true, 'City');
    }
    return Yup.string();
  }),
  mailingCity: Yup.string().when('method', (val: string) => {
    const condition = val === 'cash';
    if (condition) {
      return getCityAddressSchema(true, 'City');
    }
    return Yup.string();
  }),

  phone: Yup.string().matches(/^[0-9]{5,12}$/, 'Must be a valid number'),
  phoneCode: Yup.string().matches(
    /^(\+?\d{1,4})$/gm,
    'Must be a valid country code and started from "+"'
  ),

  twilioCode: Yup.string().matches(/^[0-9]{4}$/, 'Must be a valid code'),
});

interface IValues {
  firstName: string;
  lastName: string;
  email: string;
  taxId: string;
  confirmTaxId: string;
  taxIdType: 'EIN' | 'SSN';
  countryCode: string;
  method: string;
  state: string;
  postalCode: string;
  address1: string;
  address2: string;
  city: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  phone: string;
  phoneCode: string;
  twilioCode: string;
}

const initialValues: IValues = {
  taxIdType: 'EIN',
  firstName: '',
  lastName: '',
  email: '',
  taxId: '',
  confirmTaxId: '',
  countryCode: '',
  method: '',
  state: '',
  postalCode: '',
  address1: '',
  address2: '',
  city: '',
  mailingAddress1: '',
  mailingAddress2: '',
  mailingCity: '',
  mailingState: '',
  mailingPostalCode: '',
  phone: '',
  phoneCode: '',
  twilioCode: '',
};

const formats = {
  EIN: '##-#######',
  SSN: '###-##-####',
  default: '##################',
};

let format = formats.default;

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      format={format}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

const Details: React.FC = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectHomeUserData);
  const canEdit = useSelector(selectHomeEdit);
  const classes = useStyles();
  const countriesOptions = getCountryCodeOptions();
  const [checked, setChecked] = useState<boolean>(false);
  const { emailErrors, validateEmail } = useEmailValidator();

  const isTipaltyFlow = getIsTipaltyFlow({
    country: userData.countryCode,
    method: userData.method,
  });

  const fk: FormikProps<IValues> = useFormik({
    initialValues: {
      ...initialValues,
      ...userData,
      taxIdType: userData.taxIdType || initialValues.taxIdType,
      ...(userData?.twilioPhone && divideCodeAndPhoneNumber(userData.twilioPhone)),
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: () => {
      saveData();
    },
  });

  const saveData = () => {
    const {
      firstName,
      lastName,
      email,
      taxId,
      taxIdType,
      state,
      mailingState,
      address1,
      address2,
      city,
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      postalCode,
      mailingPostalCode,
      phoneCode,
      phone,
      method,
    } = fk.values;

    const data = {
      firstName,
      lastName,
      email,
      taxId,
      taxIdType,
      state,
      mailingState,
      address1,
      address2,
      city,
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      postalCode,
      mailingPostalCode,
      twilioPhone: phoneCode + phone,
    };

    dispatch(setHomeUserData(data));

    if (['prepaidCard', 'pushToCard', 'PayPal'].includes(method) || isTipaltyFlow) {
      dispatch(saveEditData(data));
      if (method === 'pushToCard') {
        dispatch(setHomeStep(2));
      }
    } else {
      dispatch(setHomeStep(2));
    }
  };
  const isDisabled = !canEdit;
  const createInput = inputCreator<IValues>(fk, isDisabled);
  const createSelect = selectCreator<IValues>(fk, isDisabled);

  const isPrepaidCardMethod = userData.method === 'prepaidCard';

  const onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      fk.setValues({
        ...fk.values,
        mailingAddress1: fk.values.address1,
        mailingAddress2: fk.values.address2,
        mailingCity: fk.values.city,
        mailingState: fk.values.state,
        mailingPostalCode: fk.values.postalCode,
      });
    }
    setChecked(e.target.checked);
  };

  const validateEmailAddress = async (e: React.FocusEvent<HTMLInputElement>) => {
    fk.handleBlur(e);
    const { name, value } = e.target;
    validateEmail({ name, value });
  };

  const withFormat = ['US', 'CA'].includes(fk.values.countryCode);

  const showStatePostalFields =
    ['cash', 'pushToCard'].includes(userData.method) && userData.countryCode === 'US';

  const showAddresses = ['cash', 'bankWire', 'ACH'].includes(userData.method) && !isTipaltyFlow;

  const isPayPal = userData.method === 'PayPal';

  //TODO: create more clear solution
  withFormat ? (format = formats[fk.values.taxIdType]) : (format = formats.default);

  const showPhysicalState = userData.countryCode === 'US' && userData.method === 'ACH';
  const showAchZipCode = userData.countryCode === 'US' && userData.method === 'ACH';

  console.log('fk', fk);

  const renderButtons = (submitText?: string) =>
    canEdit ? (
      <CustomButtons
        submitText={submitText}
        resetForm={() => fk.handleReset()}
        isDisabled={!!emailErrors || !fk.isValid}
      />
    ) : (
      <div />
    );

  useEffect(() => {
    if (isPayPal) {
      dispatch(setWaitingForWebhook(true));
    }
  }, [isPayPal]);

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        saveData();
      }}
    >
      {userData.method === 'pushToCard' && (
        <div>
          <Typography component="span" className={classes.instructions}>
            Please make sure to fill the form below with your VISA card details. Only then you will
            be able to use this Payment method
          </Typography>
        </div>
      )}

      {!isTipaltyFlow && (
        <Grid container spacing={2}>
          {!isPayPal && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Payee Information
                </Typography>
              </Grid>

              {createInput({ id: 'firstName', label: 'First Name', disabled: isDisabled })}
              {createInput({ id: 'lastName', label: 'Last Name', disabled: isDisabled })}

              {createInput({
                id: 'email',
                label: 'Email',
                onBlur: validateEmailAddress,
                errorText: emailErrors?.email,
                disabled: isDisabled,
              })}

              {!isPrepaidCardMethod &&
                createSelect({
                  label: 'Tax ID Type',
                  id: 'taxIdType',
                  disabled: isDisabled,
                  options: [
                    {
                      value: 'EIN',
                      label: 'EIN/TIN',
                    },
                    {
                      value: 'SSN',
                      label: 'SSN',
                    },
                  ],
                })}

              {!isPrepaidCardMethod && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={`Tax ID ${withFormat ? '' : '(Optional)'}`}
                      value={fk.values.taxId}
                      fullWidth
                      variant="outlined"
                      focused={!!fk.values.taxId && !isDisabled}
                      onChange={fk.handleChange}
                      autoComplete="new-password"
                      error={!!(fk.errors.taxId && fk.touched.taxId)}
                      onBlur={fk.handleBlur}
                      helperText={fk.touched.taxId && fk.errors.taxId}
                      required={
                        userData.method === 'ACH' || ['CA', 'US'].includes(fk.values.countryCode)
                      }
                      name="taxId"
                      id="taxId"
                      disabled={isDisabled}
                      InputProps={
                        withFormat
                          ? {
                              inputComponent: NumberFormatCustom as any,
                            }
                          : {}
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="confirmTaxId"
                      label="Confirm Tax ID"
                      name="confirmTaxId"
                      focused={!!fk.values.confirmTaxId && !isDisabled}
                      autoComplete="new-password"
                      onBlur={fk.handleBlur}
                      error={!!(fk.errors.confirmTaxId && fk.touched.confirmTaxId)}
                      helperText={fk.touched.confirmTaxId && fk.errors.confirmTaxId}
                      value={fk.values.confirmTaxId}
                      onChange={fk.handleChange}
                      disabled={isDisabled}
                      InputProps={
                        withFormat
                          ? {
                              inputComponent: NumberFormatCustom as any,
                            }
                          : {}
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid
                container
                direction={'row'}
                justify={'space-between'}
                item
                xs={12}
                sm={6}
                spacing={0}
              >
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      id="phoneCode"
                      options={countriesOptions}
                      disabled={isDisabled}
                      value={countriesOptions.find((item) => item.value === fk.values.phoneCode)}
                      onChange={(e, option) => {
                        fk.setFieldValue('phoneCode', option?.value);
                      }}
                      autoHighlight
                      getOptionLabel={(option) => option?.label}
                      renderOption={(props) => {
                        return (
                          <MenuItem key={props.value} value={props.value}>
                            {props.flag && <span>{props.flag}</span>}
                            {props.label}
                          </MenuItem>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Code"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </FormControl>

                  {/*<InputSelect*/}
                  {/*  id='phoneCode'*/}
                  {/*  label='Code'*/}
                  {/*  required*/}
                  {/*  disabled={isDisabled}*/}
                  {/*  value={fk.values.phoneCode}*/}
                  {/*  options={countriesOptions}*/}
                  {/*  error={!!(fk.errors.phoneCode && fk.touched.phoneCode)}*/}
                  {/*  onChange={(e) => {*/}
                  {/*    fk.setFieldValue('phoneCode', e.target.value);*/}
                  {/*  }}*/}
                  {/*  inputProps={{ maxLength: 9 }}*/}
                  {/*  variant='outlined'*/}
                  {/*/>*/}
                </Grid>
                <InputPhone
                  id="phone"
                  xs={9}
                  className={classes.phone}
                  label={'Phone Number'}
                  onChange={fk.handleChange}
                  onBlur={fk.handleBlur}
                  value={fk.values.phone}
                  country={userData.countryCode}
                  setFieldValue={fk.setFieldValue}
                  disabled={isDisabled}
                  // disabled={!!userData.twilioPhone}
                  required
                  errorMessage={'phone'}
                  error={
                    !!(fk.errors.phone && fk.touched.phone) ||
                    !!(fk.errors.phoneCode && fk.touched.phoneCode)
                  }
                  helperText={
                    (fk.touched.phone && fk.errors.phone) ||
                    (fk.touched.phoneCode && fk.errors.phoneCode) ||
                    ''
                  }
                />
                <small>
                  {userData.twilioPhone
                    ? 'To change a phone number, please contact our Support team'
                    : 'Please make sure to use a valid phone ' +
                      'number since it will be used for a Two-factor ' +
                      'authentication (2FA) if you decide to edit your payout information'}
                </small>
              </Grid>
            </>
          )}

          {showStatePostalFields && (
            <>
              <Grid item xs={12} sm={6}>
                <StateSelect
                  id={'state'}
                  value={fk.values.state}
                  onChange={(code: string) => fk.setFieldValue('state', code, true)}
                />
              </Grid>

              {createInput({ id: 'postalCode', label: 'Postal Code' })}
            </>
          )}
          {userData?.method === 'bankWire' &&
            createInput({ id: 'postalCode', label: 'Postal Code', isRequired: true })}
        </Grid>
      )}

      {showAddresses && (
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.paymentTitle}>
            <Typography variant="h6" component="h6">
              Physical address
            </Typography>
          </Grid>

          {createInput({ id: 'address1', label: 'Address line 1', isRequired: true })}
          {createInput({ id: 'address2', label: 'Address line 2', isRequired: true })}
          {createInput({ id: 'city', label: 'City', isRequired: true })}
          {showAchZipCode && createInput({ id: 'postalCode', label: 'Zip Code', isRequired: true })}
          {showPhysicalState && (
            <Grid item xs={12} sm={6}>
              <StateSelect
                id={'state'}
                value={fk.values.state}
                onChange={(code: string) => fk.setFieldValue('state', code, true)}
              />
            </Grid>
          )}

          {((userData.method === 'ACH' && userData.countryCode === 'US') ||
            userData.method !== 'ACH') && (
            <>
              <Grid item xs={12} className={classes.paymentTitle}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant="h6" component="h6" className={classes.mailingAddress}>
                    Mailing address
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={onCheckBoxChange}
                        name="checkedF"
                      />
                    }
                    label="Use Physical address"
                  />
                </Box>
              </Grid>

              {createInput({ id: 'mailingAddress1', label: 'Address line 1' })}
              {createInput({ id: 'mailingAddress2', label: 'Address line 2' })}
              {createInput({ id: 'mailingCity', label: 'City' })}
              {showAchZipCode && createInput({ id: 'mailingPostalCode', label: 'Zip Code' })}

              {showPhysicalState && (
                <Grid item xs={12} sm={6}>
                  <StateSelect
                    id={'mailingState'}
                    value={fk.values.mailingState}
                    onChange={(code: string) => fk.setFieldValue('mailingState', code, true)}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}

      {userData.method === 'pushToCard' && (
        <div style={{ marginTop: 20 }}>
          <IframeCmp />
        </div>
      )}

      {(isTipaltyFlow || fk.values.method === 'PayPal') && (
        <div style={{ marginTop: 20 }}>
          <TipaltiIframe method={fk.values.method} />
          <LeavePageModal />
        </div>
      )}

      <div className={classes.paymentTitle}>
        {userData.method === 'pushToCard'
          ? renderButtons('Confirm, I have completed')
          : renderButtons()}
      </div>
    </form>
  );
};

export default Details;
